import { useCallback, useEffect, useRef } from "react";

interface OutsideClickRef {
  contains: (target: EventTarget | null) => any
}

export function useOutsideClick(ref: React.RefObject<OutsideClickRef>, callback: () => any) {

  const listener = useCallback((e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', listener)

    return () => document.removeEventListener('click', listener)
  }, [])

  return ref
}
