
export function formatRelativeTimestamp(timestamp: Date) {
  const reltimeFormatter = new Intl.RelativeTimeFormat('ru', { style: 'short' })
  const timeFormatter = new Intl.DateTimeFormat('ru', { dateStyle: 'medium' })
  const diff = new Date().getTime() - timestamp.getTime()
  const priority: [Intl.RelativeTimeFormatUnit, number, number][] = [
    ['second', diff / 1000, 60],
    ['minute', diff / 1000 / 60, 60 * 60],
    ['hour', diff / 1000 / 60 / 60, 60 * 60 * 24],
    ['day', diff / 1000 / 60 / 60 / 24, 60 * 60 * 24 * 7],
    ['week', diff / 1000 / 60 / 60 / 24 / 7, 60 * 60 * 24 * 7 * 4],
  ]
  for (const v of priority) {
    if (Math.round(diff / 1000) >= v[2]) {
      continue
    }

    return reltimeFormatter.format(Math.round(-v[1]), v[0])
  }

  return timeFormatter.format(timestamp)
}
