import { NOTIFICATIONS_ENDPOINT } from "api/endpoints";
import classNames from "classnames";
import { useAPIFetch } from "hooks/fetch";
import { useOutsideClick } from "hooks/outside";
import { useUser } from "hooks/user";
import { Notification as NotificationModel } from 'models/notification'
import Link from "next/link";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useQuery } from "react-query";
import { formatRelativeTimestamp } from "utils/time";

type PropsWithNotifications<P> = P & {
  notifications: NotificationModel[]
}


export function NotificationsButton({ notifications, onClick, latestItem }: PropsWithNotifications<{
  onClick?: () => any
  latestItem?: NotificationModel
}>) {
  return <button onClick={onClick} className="flex relative">
    <img src="/images/navbar/notifications.svg" width={20} height={20} className="h-1 w-auto" />
    {notifications.length > 0 && <div className="absolute top-[-7px] right-[-7px] bg-red-600 w-[1.2rem] h-[1.2rem] flex items-center justify-center rounded-full">
      <span className="text-white text-xs">{notifications.length > 10 ? '9+' : notifications.length}</span>
    </div>}
  </button>
}

export function NotificationCard({ notification }: { notification: NotificationModel }) {
  const { title, description, link } = notification
  const inner = <div className="flex flex-col gap-0.5 items-start w-20 text-ellipsis border-light-grey">
    {title && <h2>{title}</h2>}
    <p className="text-sm text-dark-grey break-words w-full">{description}</p>
    <p className="text-sm text-grey">{formatRelativeTimestamp(new Date(notification.created_at))}</p>
  </div>

  if (link) return <a className="hover:no-underline" href={link}>{inner}</a>

  return inner
}

export function Notifications({ allData }: { allData: NotificationModel[] }) {
  return <div
    className="flex flex-col gap-1 bg-white shadow-inplace-xl p-1.5-new min-w-25 rounded-xl absolute top-2 left-1 -translate-x-full h-[50vh] max-2xl:h-[60vh]"
    tabIndex={0}
  >
    <h2 className="text-xl font-semibold">Уведомления</h2>
    <div className="flex flex-col w-full h-full items-start overflow-y-auto gap-1.5-new">
      {/* {JSON.stringify(data)} */}
      {!!allData.length ?
        allData.map(v => <NotificationCard notification={v} key={v.id} />)
      : <div className="flex flex-col gap-1 items-center justify-center w-full h-full">
        <img src="/images/not-found.png" className="w-5 h-auto" />
        <p className="text-grey">У вас нет уведомлений</p>
      </div>}
    </div>
  </div>
}

export default function NotificationsContainer() {
  const apiFetch = useAPIFetch()
  const { data, error, isLoading } = useQuery('notifications', {
    queryFn: async (): Promise<NotificationModel[]> => {
      const resp = await apiFetch(NOTIFICATIONS_ENDPOINT)
      if (!resp.ok) throw Error('failed to fetch notifications')

      const notifications = (await resp.json() as NotificationModel[]).sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      const latestItem = localStorage.getItem('notifications/latestItem')
      if (latestItem) return notifications

      return notifications
    },
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  })
  const [isOpen, setOpen] = useState<boolean>(false)
  const [latestItem, setLatestItem] = useState<NotificationModel | undefined>()


  const newData = data?.filter(v => !latestItem || Date.parse(v.created_at) > Date.parse(latestItem.created_at))
  
  useEffect(() => {
    if (!latestItem) { }

    if (latestItem) {
      localStorage.setItem('notifications/latestItem', JSON.stringify(latestItem))
    } else {
      const item = localStorage.getItem('notifications/latestItem')
      if (item) setLatestItem(JSON.parse(item))
    }

  }, [latestItem])

  const handleClick = () => {
    if (data && data.length > 0) {
      setOpen(!isOpen)
      setLatestItem(data[0])
    }
  }
  const ref = useRef(null)
  useOutsideClick(ref, () => setOpen(false))
  if (isLoading) return <div></div>
  if (error) return <div></div> // Display <Notifications> with a error

  return <div className="relative z-20" ref={ref}>
    <NotificationsButton notifications={newData!} onClick={handleClick} latestItem={latestItem} />
    {isOpen && <Notifications allData={data!}  />}
  </div>
}
